import styled from "styled-components";
import { Link } from "react-router-dom";

export const GenerateImageBlockSection = styled("section")`
  position: relative;
  padding: 2rem 0 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding: 1rem 0 1rem;
  }
`;

export const Content = styled("p")`
  padding: 0.75rem 0 0.75rem;
`;

export const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const ContentWrapper = styled("div")`
  width: 100%;
  max-width: 1200px;
  min-height: calc(100dvh - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    max-width: 540px;
  }

   @media screen and (max-width: 1024px) {
    & h6{
      margin-bottom: 1rem;
    }
  }
 
  .text-error{
    font-size: 14px;
    color: red;
    text-align: left;
    padding: 5px 0px 0px 0px;
    margin-bottom: 0;
  }
  & .div-input-search{
    display: flex;
    width: 100%;
    flex-de
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    margin-bottom: 15px;
    & input {
      resize: none; 
    }
    & input:focus-within, textarea:focus-within {
          background: rgb(241, 242, 243);
          box-shadow: none;
          
      }
  }

  & .btn-submit{
    border: 0px solid #fff;
    border-radius: 4px;
    width: 100%;
    height: 46px;
    cursor: pointer;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    background-color: rgba(24, 33, 109, 1);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    margin-top: 24px;
    &.disabled{
      background-color: rgb(158 158 158);
    }
  }
`;
