import { lazy } from "react";
import LoginForm from "../../components/LoginForm";

const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));

const Login = () => {
  return (
    <Container>
      <ScrollToTop />
      <LoginForm />
    </Container>
  );
};

export default Login;
