import { useEffect } from "react";
import { Redirect } from "react-router-dom";

const PublicProtected = (props: any) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let authUser: any = localStorage.getItem("authUser");

  if (!!authUser) {
    console.log('55555555');
    return <Redirect to="/" />
  }

  return <>{props.children}</>;
};


export default PublicProtected;