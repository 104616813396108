import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "../components/Footer";
import Home from "../pages/Home";
import Login from "../pages/Login";
import { Styles } from "../styles/styles";
import AuthProtected from "./AuthProtected";
import PublicProtected from "./PublicProtected";
import Header from "../components/Header";

const Router = () => {

  return (
    <Suspense fallback={null}>
      <Styles />
      {/* <Header /> */}
      <Switch>
        <Route
          key={'LOGIN'}
          path={"/login"}
          exact={true}
          render={(props) => (
            <PublicProtected><Login /></PublicProtected>
          )}
        />
        <Route
          key={'HOME'}
          path={["/", "/home"]}
          exact={true}
          render={(props) => (
            <AuthProtected>
              <Header />
              <Home />
            </AuthProtected>
          )}
        />
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
