import styled from "styled-components";

export const GenerateImageBlockSection = styled("section")`
  position: relative;
  padding: 2rem 0 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding: 1rem 0 1rem;
  }
`;

export const Content = styled("p")`
  padding: 0.75rem 0 0.75rem;
`;

export const ContentWrapper = styled("div")`
  width: 100%;
  max-width: 1200px;
  min-height: calc(100dvh - 400px);

  @media only screen and (max-width: 768px) {
    max-width: 540px;
  }

   @media screen and (max-width: 1024px) {
    & h6{
      margin-bottom: 1rem;
    }
  }
  & .avatar-uploader{
    padding: 0px;
    margin-top: 20px;
    height: 100%;
    min-height: 350px;
    max-height: 350px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    border: 1px dashed rgba(24, 33, 109, 0.7);
  }
  & .ant-upload-picture-card-wrapper{
    height: 100%;
  }
  & .ant-upload-select-picture-card{
    width: 100%;
    margin: 0;
    border: none;
    background-color: #f1f2f3;
    border-radius: 4px;
    height: 100%;
    
  }
  & .ant-upload.ant-upload-select-picture-card:hover {
    border-color: rgb(24, 33, 109);
  }

  & .btn-upload{
    border: 0px solid #fff;
    border-radius: 50px;
    width: 100px;
    height: 30px;
    cursor: pointer;
    color: rgb(24, 33, 109);
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    &:hover{
        background-color: rgba(24, 33, 109, 0.2);
    }
  }
  & .btn-send{
    border: 0px solid #fff;
    border-radius: 4px;
    width: 150px;
    height: 46px;
    cursor: pointer;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    background-color: rgba(24, 33, 109, 1);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    margin-top: 24px;
    &.disabled{
      background-color: rgb(158 158 158);
    }
  }

  & .box-preview-image-generate{
      min-width: 300px;
      margin-top: 20px;
      border-radius: 8px !important;
      border-radius: 4px !important;
      overflow: hidden;
      position: relative;
      height: auto;
      .ant-image{
        border-radius: 4px !important;
        width: 100%;
        min-height: 350px;
      }
      .loading-image{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(24, 33, 109);
        font-size: 30px;
      }
      .loading-download{
        position: absolute;
        top: 20px;
        right: 20px;
        color: rgb(24, 33, 109);
        font-size: 30px;
        cursor: pointer;
        &:hover{
            transform: scale(1.1);
        }
      }
      & img{
        object-fit: cover
      };
  }

  & .div-box-search{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%
    justify-content: space-between;
    align-items: center;
    padding: 0px;

  }
  & .div-files{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
     & .preview-image{
      border-radius: 0px;
      & img{
                object-fit: cover
      };
      
      .btn-remove-image{
              border: 0px solid #fff;
              border-radius: 50px;
              width: 30px;
              height: 30px;
              cursor: pointer;
              color: red;
              font-weight: bold;
              background-color: #fff;
              justify-content: center;
              align-items: center;
              text-align: center;
              position: absolute;
              top: 8px;
              right: 8px;
              padding: 0;
              font-size: 22px;
              transition: transform .2s;
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
              &:hover{
                  transform: scale(1.1);
              }
        };
    }
  }
  & .div-input-search{
    display: flex;
    width: 100%;
    flex-de
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    margin-bottom: 15px;
    & textarea {
      resize: none; 
    }
    & textarea:focus-within, textarea:focus-within {
          background: rgb(241, 242, 243);
          box-shadow: none;
          
      }
  }
  & .div-buttons{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px 1.25rem;
    border: none;
  }

  .text-error{
  font-size: 14px;
  color: red;
  text-align: left;
  padding: 0px;
  margin-bottom: 0;
  }

    .text-error-prompt{
  font-size: 14px;
  color: red;
  text-align: left;
  padding: 5px 0px 0px 0px;
  margin-bottom: 0;
  }

  .d-flex-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }
`;
